import Network from '@/services/network'
import {ref} from 'vue'

export default function handleCompanyContact() {
    const network = new Network;
    const loading = ref(false)

    //Contact register
    const fetchCompanyAllContacts = (query) => {
        return network.api('get', `/company/contacts/list` + query);
    }
    const fetchContactDetails = (type, id, query) => {
        return network.api('get', `/company/contacts/details/${type}/${id}` + query);
    }

    // Add contact
    const fetchCompanyContacts = (query) => {
        return network.api('get', `/company/contacts` + query);
    }
    const fetchSingleCompanyContact = (query, id) => {
        return network.api('get', `/company/contacts/${id}` + query);
    }
    const fetchSingleCompanyContactItems = (query, id) => {
        return network.api('get', `/company/contacts/items/${id}` + query);
    }
    const storeCompanyContact = (data, query) => {
        return network.api('post', '/company/contacts' + query, data);
    }
    const storeCompanyContactsItems = (data, query) => {
        return network.api('post', '/company/contacts/items' + query, data);
    }
    const storeAdditionalContactItems = (contact_id, data, query) => {
        return network.api('post', `/company/contacts/additional-items/${contact_id}` + query, data);
    }
    const updateContactDealer = (contact_id, data) => {
        return network.api('post', `/company/contacts/update-contact-dealer/${contact_id}`, data);
    }
    const storeUpdateContactFile = (contact_id, data, query) => {
        return network.api('post', `/company/contacts/file-upload/${contact_id}` + query, data);
    }

    //Contact import
    const uploadContacts = (data) => {
        return network.api('post', '/company/contacts/upload-contacts', data);
    }
    const insertContacts = (data) => {
        return network.api('post', '/company/contacts/final-contact-upload', data);
    }

    //Activity Logs
    const fetchActivityLogs = (query) => {
        return network.api('get', '/company/contacts/activity-logs' + query);
    }
    const storeActivityLogs = (data, query) => {
        return network.api('post', '/company/contacts/activity-logs' + query, data);
    }
    const updateActivityLogs = (id, data, query) => {
        return network.api('post', `/company/contacts/activity-logs/${id}` + query, data);
    }

    return {
        fetchCompanyAllContacts,
        fetchContactDetails,

        fetchCompanyContacts,
        fetchSingleCompanyContact,
        fetchSingleCompanyContactItems,
        storeCompanyContact,
        storeCompanyContactsItems,
        storeAdditionalContactItems,
        updateContactDealer,
        storeUpdateContactFile,
        uploadContacts,
        insertContacts,

        fetchActivityLogs,
        storeActivityLogs,
        updateActivityLogs,
        loading
    }
}
